@use "../variables.scss";

.service-item-bloc {
  border-radius: 0.5rem;
  //padding: 1.5rem;
  max-width: 875px;
  //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin: 1rem 0 3rem 0;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // transition: transform 0.3s ease, box-shadow 0.3s ease;
  // &:hover {
  //   transform: scale(1.02);
  //   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  // }
  @media (max-width: 1200px) {
    margin: 1rem 3rem 3rem 3rem;
  }
  @media (max-width: 875px) {
    margin: 1rem 1rem 3rem 1rem;
  }
  h3 {
    font-size: 1.5rem;
    color: variables.$green;
    // margin-bottom: 0.75rem;
    border-bottom: 3px solid;
    padding-bottom: 2px;
  }
  .service-list {
    margin: 0;
  }
  a {
    align-self: flex-end;
    text-decoration: none;
    color: variables.$green;
    font-weight: 500;
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
    border: 1px solid variables.$green;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    &:hover,
    &:focus {
      background-color: variables.$green;
      color: white;
      transform: scale(1.05);
    }
  }
}
