@use "../variables.scss";
@use "../mixins.scss";

.hero-pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: variables.$hero-background;
  .hero-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1240px;
    // width: 100%;
    padding: 3.2rem 0;
    @include mixins.responsive-padding(3rem, 2rem, 1rem);
    gap: .5rem;
    color: variables.$color-title;
    .hero-pages-title {
      font-size: 3.8rem;
      font-weight: 700;
      padding-top: 1.5rem;
      @media (max-width: 1200px) {
        font-size: 3.5rem;
      }
      @media (max-width: 1000px) {
        font-size: 3rem;
      }
    }
    .hero-pages-description {
      font-size: 1.2rem;
      width: 75%;
      margin: 0;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }
}
