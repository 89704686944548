@use './components/navbar';
@use './components/footer';
@use './components/freeContents';
@use './components/heroHome';
@use './components/aboutHome';
@use './components/services';
@use './components/heroPages';
@use './components/pagesCopy';
@use './components/mentoringServices';
@use './components/serviceCards';
@use './components/serviceItemBloc';
@use './components/consultingServices';
@use './components//consultingEmotional';
@use './components/howItWorks';
@use './components/cta';
@use './components/popUpMessage';
@use './components/courses';
@use './components/copyAboutMe';
@use './components/mission';
@use './components/ebooksList';
@use './components/modalForm';
@use './components/loginForm';
@use './components/adminHeader';
@use './components/adminMessages';
@use './components//adminBoutons';
@use './components/adminMentoringServices';
@use './components//popUpZap';

html,
body {
  font-family: "Kumbh sans", sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-weight: 400;
  font-size: 1rem;
}

main {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  // max-width: 1240px;
}

.content {
  opacity: 0; 
  transition: opacity 0.5s ease; 
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  @media (max-width: 1024px) {
    gap: 4rem;
  }
  & > *:nth-child(1) {
    margin-bottom: -1.2rem; 
  }
}
.content.loaded {
  opacity: 1; 
}
